// src/pages/Main.jsx

import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import arrow from "./../assets/arrow.svg";
import unstructuredCV from "./../assets/unstructuredCV.gif";
import standardizedCV from "./../assets/standardizedCV.gif";
import scoreExplanation from "./../assets/ScoreAlexisOthers.png";
import Footer from "../components/Footer";
import { useRef } from "react";

export default function Main({ user, setUser, loading }) {
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const handleFullScreen = () => {
    if (iframeRef.current) {
      iframeRef.current.requestFullscreen();
    }
  };
  const handleAnalyzeClick = () => {
    // Redirect directly to Oculus store
    window.open(
      "https://www.meta.com/en-gb/experiences/metablogger/9107482075996173/",
      "_blank"
    );
  };

  useEffect(() => {
    const payload = JSON.parse(localStorage.getItem("analyzePayload"));
    if (user && payload) {
      localStorage.removeItem("analyzePayload");
    }
  }, [user]);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div
        className="sticky top-0 right-0 p-4"
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 50 }}
      >
        <button
          className="btn btn-primary text-2xl py-2 px-4"
          onClick={handleAnalyzeClick}
        >
          Download from the Meta Store
        </button>
      </div>
      <div
        className="flex-grow flex flex-col justify-center items-center text-center px-8 md:px-40"
        style={{ marginTop: "180px" }}
      >
        <h1 className="font-bold md:text-8xl text-2xl sm:text-3xl mb-7">
          MetaBlogger VR<br></br>
        </h1>
        <h2 className="text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl">
          Every day, millions of people watch online videos, but the experience
          is completely one-sided. You sit back, watch, and if you have a
          question, you can only hope for an answer buried in a sea of comments.
          The creators you follow dictate the pace, the topics, and the
          flow—there's no way to engage in real-time. On top of that,
          traditional content consumption is limited to flat, 2D screens. No
          matter how immersive the storytelling, you're still just an observer,
          not a participant. MetaBlogger VR revolutionizes content consumption
          by making it interactive, responsive, and fully immersive. Instead of
          passively watching a video, you step inside a VR-powered world where
          AI-driven metabloggers replace traditional human creators. These AI
          hosts are not just digital avatars—they respond instantly to your
          questions, adapt to your interests, and engage with you in a way that
          no pre-recorded video ever could.
        </h2>
        {errorMsg && (
          <p className="text-red-500 max-w-64 capitalize mt-4">{errorMsg}</p>
        )}
      </div>

      <div className="text-center mb-40 mt-60">
        <h1 className="font-bold text-5xl mb-6">Check out a demo video!</h1>
        <p className="text-lg md:text-xl text-base sm:text-lg mb-10 max-w-3xl mx-auto">
          Watch our demo to see it in action!
        </p>
        <div className="flex justify-center flex-col items-center">
          <iframe
            ref={iframeRef}
            width="660"
            height="415"
            src="https://www.youtube.com/embed/5o6bbcJxDB8"
            title="Demo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <Footer />
    </div>
  );
}
