// src/App.js

import React, { createContext, useEffect, useState } from "react";
import Main from "./pages/Main";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { auth } from "./firebase";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import UploadCVS from "./pages/UploadCVS";
import CandidatesCards from "./pages/Candidates";
import Jobs from "./pages/Jobs";
import StandardizedCV from "./pages/StandardizedCV";
import Billing from './pages/Billing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Navbar from './components/Navbar';

export const AppContext = createContext();

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [id, setId] = useState('');

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // or any loading component
    }

    return (
        <GoogleOAuthProvider clientId='YOUR_CLIENT_ID_HERE'>
            <AppContext.Provider value={{ candidates: candidates, setCandidates: setCandidates, id: id, setId: setId }}>
                <BrowserRouter>
                    <ConditionalNavbar user={user} setUser={setUser} />
                    <Routes>
                        <Route path="/" element={<Main setUser={setUser} user={user} loading={loading} />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/signin" element={<SignInPage setUser={setUser} />} />
                        <Route path="/signup" element={<SignUpPage setUser={setUser} />} />
                        <Route path="/reset-password" element={<ResetPasswordPage />} />
                        {/* Routes that previously required authentication are now accessible without auth check */}
                        <Route path="/add-cvs" element={<UploadCVS user={user}/>} />
                        <Route path="/role/:roleUrl" element={<CandidatesCards user={user}/>} />
                        <Route path="/jobs" element={<Jobs user={user} />} />
                        <Route path="/standardized-cv/:candidateName" element={<StandardizedCV user={user} />} />
                        <Route path="/billing" element={<Billing user={user}/>} />
                    </Routes>
                </BrowserRouter>
            </AppContext.Provider>
        </GoogleOAuthProvider>
    );
}

const ConditionalNavbar = ({ user, setUser }) => {
    const location = useLocation();
    const noNavbarRoutes = ["/", "/signin", "/signup", "/reset-password", "/privacy", "/terms"];
    
    if (noNavbarRoutes.includes(location.pathname)) {
        return null;
    }

    return <Navbar user={user} setUser={setUser} />;
};

export default App;