import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Privacy() {
  return (
    <div className="text-sm md:text-base">
      <Header title="Privacy Policy" />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p>Introduction</p>
        <p>
          At Alindor Corp. ("Company" or "We") we respect the privacy of
          Customers and Users ("you") and are committed to protecting it through
          our compliance with this policy.
        </p>
        <p>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website alindor.tech (our
          “Website”), use our Subscription Services, apply for a job, or
          participate in interviews or other candidate processes, and our
          practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </p>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, and other electronic messages between you and this
            Website.
          </li>
          <li>
            Through mobile and desktop applications you download from this
            Website, which provide dedicated non-browser-based interaction
            between you and this Website.
          </li>
        </ul>
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>Any other website operated by Company or any third party; or</li>
          <li>
            Any third party, including through any application or content
            (including advertising) that may link to or be accessible from or on
            the Website.
          </li>
        </ul>
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time (see Changes
          to Our Privacy Policy). Your continued use of this Website after we
          make changes is deemed to be acceptance of those changes, so please
          check the policy periodically for updates.
        </p>
        <p>
          Additionally, with our partnership and commitment to Security and
          Privacy with Microsoft please see their detailed privacy statement
          here.
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Children Under the Age of 16
        </h2>
        <p>
          Our Website is not intended for children under 16 years of age. No one
          under age 16 may provide any information to or on the Website. We do
          not knowingly collect personal information from children under 16. If
          you are under 16, do not use or provide any information on this
          Website or on or through any of its features. If we learn we have
          collected or received personal information from a child under 16
          without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 16, please contact us at:
        </p>
        <p>legal@alindor.tech</p>
        <p>
          California residents under 16 years of age may have additional rights
          regarding the collection and sale of their personal information.
          Please see Your California Privacy Rights for more information.
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Information We Collect About You and How We Collect It
        </h2>
        <p>
          We collect several types of information from and about users of our
          Website, including information:
        </p>
        <ul>
          <li>
            By which you may be personally identified, such as name, postal
            address, e-mail address, telephone number, social security number
            (“personal information”);
          </li>
          <li>
            That is about you but individually does not identify you; and/or
          </li>
          <li>
            About your internet connection, the equipment you use to access our
            Website, and usage details.
          </li>
        </ul>
        <p>We collect this information:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses, and
            information collected through cookies, web beacons, and other
            tracking technologies.
          </li>
          <li>From third parties, for example, our business partners.</li>
        </ul>

        <h3 className="text-xl font-bold mt-4">
          Information You Provide to Us
        </h3>
        <p>The information we collect from you may include:</p>
        <ul>
          <li>
            Information that you provide by filling in forms and participating
            in chats on our Webpages, or applying for a job, or participating in
            interviews or other candidate processes. This includes information
            provided at the time of registering to use our Website, subscribing
            to our service, or posting material. We may also ask you for
            information when you report a problem with our Website.
          </li>
          <li>
            Information provided relating to points of contact or other
            employees of Customer related to Subscription Services.
          </li>
          <li>
            Records and copies of your correspondence (including email
            addresses), if you contact us.
          </li>
          <li>
            Information normally contained on a resume, curriculum vitae or job
            application.
          </li>
          <li>Information regarding references for you.</li>
          <li>
            No sensitive personal information as that term is defined under the
            California Privacy Rights Act.
          </li>
        </ul>
        <p>
          Customer and User acknowledge that the Website, Subscription Service,
          or any related service is not intended for use with protected health
          information under HIPAA, credit card numbers, financial account
          numbers, or other similarly-sensitive personal information, and that
          Customer assumes all risk arising from request or use of any such
          sensitive information, including the risk of any inadvertent
          disclosure or unauthorized access thereto.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Information We Collect Through Automatic Data Collection Technologies
        </h3>
        <p>
          As you navigate through and interact with our Website, or use our
          services, we may use automatic data collection technologies to collect
          certain information about your equipment, browsing actions, and
          patterns, including:
        </p>
        <ul>
          <li>
            Details of your visits to our Website, including location data and
            other communication data and the resources that you access and use
            on the Website.
          </li>
          <li>
            Information about your computer and internet connection, including
            your IP address, operating system, and browser type.
          </li>
        </ul>
        <p>
          The information we collect automatically may include personal
          information. It helps us to improve our Website and to deliver a
          better and more personalized service, including by enabling us to:
        </p>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <p>
          The technologies we use for this automatic data collection may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies (or browser cookies)</strong>. A cookie is a small
            file placed on the hard drive of your computer. You may refuse to
            accept browser cookies by activating the appropriate setting on your
            browser. However, if you select this setting you may be unable to
            access certain parts of our Website. Unless you have adjusted your
            browser setting so that it will refuse cookies, our system will
            issue cookies when you direct your browser to our Website.
          </li>
          <li>
            <strong>Web Beacons</strong>. Pages of our Website may contain small
            electronic files known as web beacons (also referred to as clear
            gifs, pixel tags, and single-pixel gifs) that permit the Company,
            for example, to count users who have visited those pages and for
            other related website statistics (for example, recording the
            popularity of certain website content and verifying system and
            server integrity).
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-4">How We Use Your Information</h2>
        <p>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </p>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>

        <h2 className="text-2xl font-bold mt-4">
          Disclosure of Your Information
        </h2>
        <p>
          We may disclose aggregated information about our Users and Customers,
          and information that does not identify any individual, without
          restriction.
        </p>
        <p>
          We may disclose personal information that we collect or you provide as
          described in this privacy policy:
        </p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business and who are bound by contractual obligations to
            keep personal information confidential and use it only for the
            purposes for which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Alindor Corp.’s assets, whether as a
            going concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by Alindor Corp.
            about our Website users is among the assets transferred.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
        </ul>
        <p>We may also disclose your personal information:</p>
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            In support of this and other agreements, including for billing and
            collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Alindor Corp., our Customers, Users,
            or others.
          </li>
        </ul>
        <p>
          <strong>Consent to Record:</strong> Alindor Corp. may record telephone
          calls between it and Users, or between Customers and Users for quality
          control and analysis. By using our Services, Customers and Users
          consent to the recording of these telephone calls.
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Choices About How We Use and Disclose Your Information
        </h2>
        <p>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </p>
        <ul>
          <li>
            <strong>Tracking Technologies and Advertising</strong>. You can set
            your browser to refuse all or some browser cookies, or to alert you
            when cookies are being sent. Most web browsers and some mobile
            operating systems and mobile applications include a Do-Not-Track
            (“DNT”) feature or setting you can activate to signal your privacy
            preference not to have data about your online browsing activities
            monitored and collected. If you disable or refuse cookies, please
            note that some parts of this site may then be inaccessible or not
            function properly. No uniform technology standard for recognizing
            and implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this Privacy Policy.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-4">
          Accessing and Correcting Your Information
        </h2>
        <p>
          You can review and change your personal information by logging into
          the Website and visiting your account profile page.
        </p>
        <p>
          You may also send us an email at legal@alindor.tech to request access
          to, correct, or delete any personal information that you have provided
          to us. We cannot delete your personal information except by also
          deleting your user account. We may not accommodate a request to change
          information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </p>
        <p>
          Proper access and use of information provided on the Website is
          governed by our terms of use.
        </p>

        <h2 className="text-2xl font-bold mt-4">Data Security</h2>
        <p>
          Alindor Corp. maintains appropriate administrative, technical and
          organizational measures, internal controls, and data security routines
          intended to protect Customer and User Data against accidental loss or
          change, unauthorized disclosure or access, or unlawful destruction.
          Notwithstanding the foregoing, Customer and User acknowledge and
          agrees that hosting data online involves risks of unauthorized
          disclosure or exposure and that, in accessing and using the
          Subscription Service, Customer assumes all such risks. Alindor Corp.
          offers no representation, warranty, or guarantee that User Data will
          not be exposed or disclosed through errors or the actions of third
          parties. Alindor Corp. will not be responsible for any distribution,
          publication, display, or other disclosure of User Data or by Users via
          the Subscription Service.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </p>

        <h2 className="text-2xl font-bold mt-4">Links to Other Websites</h2>
        <p>
          This website may link to websites not operated by us. As such, we do
          not control or monitor the information provided on those websites and
          they are subject to their own legal and privacy policies. Over time
          the content of those websites may change or disappear. It is your sole
          responsibility to use discretion and to review their privacy policies
          and legal compliance.
        </p>

        <h2 className="text-2xl font-bold mt-4">GDPR Compliance</h2>
        <p>
          If you are a resident of the European Economic Area or the General
          Data Protection Regulation (GDPR) otherwise applies to you, you have
          certain data protection rights under the GDPR. We aim to take
          reasonable steps to ensure that your Personally Identifiable
          Information or PII (as defined in Alindor Corp.’s Terms and
          Conditions) is treated securely and in accordance with this privacy
          policy, Alindor Corp.’s Terms and Conditions, and applicable data
          protection laws.
        </p>

        <h3 className="text-xl font-bold mt-4">Legal Basis for Processing</h3>
        <p>
          We process your PII data on the basis of one or more of the following
          legal grounds, as required under GDPR: (a) Your consent to specific
          processing activities; (b) Performance of a contract to which you are
          a party; (c) Compliance with a legal obligation to which we are
          subject; (d) Alindor Corp.’s legitimate interests or those of a third
          party, provided that your interests and fundamental rights do not
          override those interests.
        </p>

        <h3 className="text-xl font-bold mt-4">Your Data Protection Rights</h3>
        <p>
          Under GDPR, you have the following rights: (a) The right to access,
          update or delete the PII data we have about you; (b) The right to
          rectification if your PII data is inaccurate or incomplete; (c) The
          right to object to Alindor Corp.’s processing of your PII data; (d)
          The right to restrict the processing of your PII data under certain
          circumstances; (e) The right to data portability, which allows you to
          request a copy of your PII data in a commonly used format; and (f) The
          right to withdraw consent for processing activities based on consent.
        </p>
        <p>
          To exercise any of your data protection rights, please contact us at
          legal@alindor.tech. We may require you to verify your identity before
          responding to your request.
        </p>

        <h3 className="text-xl font-bold mt-4">Data Transfers</h3>
        <p>
          If we transfer your PII data outside the EEA, we will ensure that
          appropriate safeguards are in place to protect your PII data, in
          compliance with GDPR requirements.
        </p>

        <h3 className="text-xl font-bold mt-4">Data Retention</h3>
        <p>
          We will retain your PII data only for as long as necessary to fulfill
          the purposes for which it was collected, including for the purposes of
          satisfying any legal, accounting, or reporting requirements.
        </p>

        <h3 className="text-xl font-bold mt-4">Contact Information</h3>
        <p>
          If you have any questions or concerns about Alindor Corp.’s data
          processing practices, or if you would like to exercise any of your
          data protection rights, please contact us at legal@alindor.tech.
        </p>

        <h2 className="text-2xl font-bold mt-4">California Privacy Rights</h2>
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our Customers or Users who are California
          residents to request and obtain from us, once a year and free of
          charge, information about categories of personal information (if any)
          we disclosed to third-parties for direct marketing purposes and the
          names and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          submit your request in writing to us using the contact information
          provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Application, you have the right to request
          removal of unwanted data that you publicly post on the Application. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Application,
          but please be aware that the data may not be completely or
          comprehensively removed from our systems.
        </p>
        <p>
          If you live in California, we have updated this privacy policy in
          compliance with the California Consumer Privacy Act of 2018 (CCPA), as
          amended by the California Privacy Rights Act (CPRA). Any questions or
          data requests, or requests to have your data deleted can be sent to
          legal@alindor.tech.
        </p>
        <p>
          Alindor Corp. does not sell, rent, loan, release, disclose,
          disseminate, make available, transfer, or otherwise allow third party
          access to your PII except as set forth in this privacy policy and
          Alindor Corp.’s Terms and Conditions or as otherwise agreed in writing
          between you and Alindor Corp. Further, Alindor Corp. does not collect,
          store, process, sell or otherwise transfer sensitive personal
          information as that term is defined in the CPRA.
        </p>
        <p>
          California law permits residents of California to request notice of
          how their personal information (as that term is defined in the CPRA)
          is shared with third parties or to opt out of such sharing. If you are
          a California resident and would like a copy of this notice or to opt
          out, please contact us at legal@alindor.tech.
        </p>
        <p>
          California law requires us to disclose the following information about
          Alindor Corp.’s collection, use, and disclosure of your personal
          information.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Personal Information Collected
        </h3>
        <p>
          We collect the following categories of personal information:
          identifiers (including name, email address, and contact information);
          commercial information; internet or other electronic network activity
          information; geolocation data; audio or electronic information;
          professional or employment-related information; and education
          information. We collect each category of personal information listed
          above for the business or commercial purposes described in this
          privacy policy and Alindor Corp.’s Terms and Conditions.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Personal Information Disclosed
        </h3>
        <p>
          We disclose the above categories of personal information for the
          business or commercial purposes described in this privacy policy and
          Alindor Corp.’s Terms and Conditions.
        </p>

        <h3 className="text-xl font-bold mt-4">
          Sources of Personal Information and Third Parties
        </h3>
        <p>
          We collect each category of personal information listed above from
          Customers and Users (as those terms are defined in Alindor Corp.’s
          Terms and Conditions). We may share each category of personal
          information listed above with third parties for the business or
          commercial purposes described in this privacy policy and Alindor
          Corp.’s Terms and Conditions.
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Changes to Our Privacy Policy
        </h2>
        <p>
          It is our policy to post any changes we make to our privacy policy on
          this page with a notice that the privacy policy has been updated on
          the Website home page. If we make material changes to how we treat our
          users’ personal information, we will notify you through a notice on
          the Website home page. The date the privacy policy was last revised is
          identified at the top of the page. You are responsible for ensuring we
          have an up-to-date active and deliverable email address for you, and
          for periodically visiting our Website and this privacy policy to check
          for any changes.
        </p>

        <h2 className="text-2xl font-bold mt-4">Your Rights</h2>
        <p>
          You have the following rights with respect to your Personal
          Information:
        </p>
        <ul>
          <li>
            The right to know about the Personal Information that we collect
            about you and to know how it is used and shared;
          </li>
          <li>
            The right to delete Personal Information collected from you
            consistent with applicable law;
          </li>
          <li>
            The right to opt-out of the sale or sharing of your Personal
            Information;
          </li>
          <li>The right to non-discrimination in exercising your rights;</li>
          <li>
            The right to correct inaccurate Personal Information that we have
            about you; and
          </li>
          <li>
            The right to limit the use and disclosure of sensitive Personal
            Information collected about you.
          </li>
        </ul>

        <h2 className="text-2xl font-bold mt-4">Third-Party Providers</h2>
        <p>
          We may share your personal information with certain third-party service providers to enhance our services and provide specific functionalities. These third-party providers include:
        </p>
        <ul>
          <li>
            AI and Machine Learning Services: OpenAI, Inc., Google Gemini, Fireworks AI, Inc., and Eleven Labs, Inc. for processing and generating AI-powered features and content;
          </li>
          <li>
            Development and Repository Services: GitHub, Inc. for code storage, version control, and development purposes;
          </li>
          <li>
            Cloud Infrastructure: Google Cloud Platform (GCP), Amazon Web Services, Inc. (AWS), and Google Firebase for hosting, data storage, and application services;
          </li>
          <li>
            Speech Recognition and Conversational AI: Deepgram, Inc. and Convai Technologies, Inc. for voice recognition, transcription, and interactive conversational features.
          </li>
        </ul>
        <p>
          When we share information with these third-party providers, they are obligated to use your personal information only as necessary to provide the requested services to us and in accordance with applicable data protection laws. These providers maintain their own privacy policies regarding how they process personal information, and we recommend reviewing their respective privacy policies for more information.
        </p>
        <p>
          We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk when sharing data with these providers, and we regularly review our arrangements with them to ensure compliance with our privacy commitments.
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Additional Conditions for Virtual Reality Applications
        </h2>
        <p>
          Our Virtual Reality (VR) applications collect and process specific
          data to provide a seamless and immersive user experience. Below are
          the additional conditions specific to our VR applications:
        </p>
        <h3 className="text-xl font-semibold mt-3">
          Information We May Collect
        </h3>
        <ul className="list-disc ml-6">
          <li>
            <strong>User ID:</strong> Used to identify and authenticate users
            within the app.
          </li>
          <li>
            <strong>User Profile Information:</strong> Includes username,
            avatar, and other profile-related data for personalization.
          </li>
          <li>
            <strong>User Age Group:</strong> Collected to ensure compliance with
            age restrictions and provide age-appropriate features.
          </li>
          <li>
            <strong>Billing Information:</strong> Includes payment details for
            in-app purchases or subscription services.
          </li>
          <li>
            <strong>Avatar Data:</strong> Used to display and manage user
            avatars in the VR environment.
          </li>
          <li>
            <strong>Audio Recordings:</strong> Captured during gameplay for
            features like feedback on public speaking performance and speech
            analysis.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-3">
          How We Use Your Information
        </h3>
        <ul className="list-disc ml-6">
          <li>
            <strong>User ID:</strong> To uniquely identify and authenticate
            users in the app.
          </li>
          <li>
            <strong>User Profile Information:</strong> To personalize the app
            experience and display user details (e.g., avatar and username) in
            social and gameplay features.
          </li>
          <li>
            <strong>User Age Group:</strong> To ensure compliance with
            age-related regulations and provide age-appropriate experiences.
          </li>
          <li>
            <strong>Billing Information:</strong> To process payments and manage
            subscriptions or in-app purchases securely.
          </li>
          <li>
            <strong>Avatar Data:</strong> To render and manage user avatars
            within the VR experience.
          </li>
          <li>
            <strong>Audio Recordings:</strong> To provide speech analysis,
            feedback, and performance insights for public speaking practice.
          </li>
          <li>
            <strong>Improving App Features:</strong> Analyze usage trends and
            improve the app's performance, features, and content.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-3">
          How to Request Data Deletion
        </h3>
        <p>
          You have the right to request the deletion of your data at any time.
          To do so, please follow these steps:
        </p>
        <ol className="list-decimal ml-6">
          <li>
            Contact us at{" "}
            <a
              href="mailto:legal@alindor.tech"
              className="text-blue-600 underline"
            >
              legal@alindor.tech
            </a>{" "}
            with your request.
          </li>
          <li>
            Specify the data you want to delete (e.g., User ID, Profile Data,
            Billing Information, etc.).
          </li>
          <li>
            We will verify your identity to ensure the security of your request.
          </li>
          <li>
            Once verified, we will delete your data within 30 days, except where
            retention is required for legal or contractual obligations.
          </li>
        </ol>

        <h3 className="text-xl font-semibold mt-3">Data Protection Measures</h3>
        <p>
          We are committed to protecting your data and have implemented the
          following security measures:
        </p>
        <ul className="list-disc ml-6">
          <li>
            <strong>Encryption:</strong> All sensitive data is encrypted in
            transit and at rest.
          </li>
          <li>
            <strong>Access Controls:</strong> Data access is restricted to
            authorized personnel only.
          </li>
          <li>
            <strong>Regular Audits:</strong> We perform regular audits and
            assessments to ensure compliance with data protection standards.
          </li>
          <li>
            <strong>Third-Party Agreements:</strong> All third-party service
            providers are required to adhere to our data protection policies and
            standards.
          </li>
        </ul>
        <p>
          Despite our efforts, no system is 100% secure, and we encourage users
          to take precautions when sharing their information.
        </p>

        <h2 className="text-2xl font-bold mt-4">Contact Information</h2>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at:
        </p>
        <p>legal@alindor.tech</p>
        <p>
          To register a complaint or concern, please email us at
          legal@alindor.tech
        </p>
        <Footer />
      </div>
    </div>
  );
}
